import { createContext, useContext } from "react";
import { IntlProvider } from "react-intl";
import "@formatjs/intl-relativetimeformat/polyfill";
import "@formatjs/intl-relativetimeformat/locale-data/es";
import "@formatjs/intl-relativetimeformat/locale-data/br";

import esMessages from "./messages/es.json";
import ptBRMessages from "./messages/pt-br.json";

const allMessages = {
  es: esMessages,
  "pt-BR": ptBRMessages,
};

const I18N_CONFIG_KEY = process.env.REACT_APP_I18N_CONFIG_KEY || "i18nConfig";

// Side effect
export function setLanguage(lang: string) {
  localStorage.setItem(I18N_CONFIG_KEY, JSON.stringify({ language: lang }));
  window.location.reload();
}

interface LocalizationProps {
  language: "pt-BR" | "es";
  setLanguage: (lang: string) => void;
}

function getSelectedLanguage(): LocalizationProps["language"] {
  const ls = localStorage.getItem(I18N_CONFIG_KEY);
  if (ls) {
    try {
      const config = JSON.parse(ls);
      return config.language;
    } catch (er) {
      console.error(er);
    }
  }
  return "pt-BR";
}

const initialState: LocalizationProps = {
  language: getSelectedLanguage(),
  setLanguage,
};

const I18nContext = createContext<LocalizationProps>(initialState);

export function useTranslation() {
  return useContext(I18nContext);
}

function I18nProvider({ children }: React.PropsWithChildren<any>) {
  const messages = allMessages[initialState.language];

  console.log("LANGUAGE: ", initialState.language);

  return (
    <I18nContext.Provider value={initialState}>
      <IntlProvider locale={initialState.language} messages={messages}>
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
}

export { I18nProvider };
