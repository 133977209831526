import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../_metronic/assets/ts/_utils";
import RatingDistributionItem from "./RatingDistributionItem";
import { useIntl } from "react-intl";

type Props = {
  data: any;
  totalReviews: number;
};

const RatingDistribution: FC<Props> = ({ data, totalReviews }) => {
  const intl = useIntl();
  return (
    <div className="card h-500px">
      <div className="card-header border-0 pt-9">
        <div className="card-title m-0 flex-column align-items-start">
          <span className="card-label fw-bolder fs-3 mb-0">
            {intl.formatMessage({
              id: "page.dashboard.score-distribution",
              defaultMessage: "Distribuição das notas",
            })}
          </span>
          <span className="text-gray-400 mb-2 fw-semibold fs-7">
            {intl.formatMessage({
              id: "page.dashboard.published-reviews",
              defaultMessage: "Avaliações publicadas",
            })}
          </span>
        </div>
      </div>

      <div className="card-body p-9 pt-4">
        {data.map((item: any) => {
          return (
            <RatingDistributionItem data={item} totalReviews={totalReviews} />
          );
        })}
      </div>
    </div>
  );
};

export default RatingDistribution;
