import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../_metronic/assets/ts/_utils";
import { IntlShape, useIntl } from "react-intl";

type Props = {
  data: any;
};

const getReviewStatus = (status: any, intl: IntlShape) => {
  switch (status) {
    case "published":
      return {
        text: intl.formatMessage({
          id: "chart-published",
          defaultMessage: "publicadas",
        }),
        color: "#63DE9C",
      };
    case "filtered":
      return {
        text: intl.formatMessage({
          id: "chart-filtered",
          defaultMessage: "filtradas",
        }),
        color: "#398FF3",
      };
    case "removed":
      return {
        text: intl.formatMessage({
          id: "chart-removed",
          defaultMessage: "removidas",
        }),
        color: "#ED5564",
      };
    case "sent":
      return {
        text: intl.formatMessage({
          id: "chart-pending",
          defaultMessage: "pendentes",
        }),
        color: "#F3B244",
      };
    default:
      return {
        text: intl.formatMessage({
          id: "chart-unknown",
          defaultMessage: "status desconhecido",
        }),
        color: "#999999",
      };
  }
};
const ReviewsByChannel: FC<Props> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data, intl)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div className="card">
      <div className="card-header border-0 pt-9">
        <div className="card-title m-0 flex-column">
          <span className="card-label fw-bolder fs-3 mb-0">
            {intl.formatMessage({
              id: "page.dashboard.review-by-status",
              defaultMessage: "Avaliações por status",
            })}
          </span>
          <span className="text-gray-400 mb-2 fw-semibold fs-7">
            {intl.formatMessage({
              id: "page.dashboard.review-by-status-caption",
              defaultMessage: "Todas as avaliações coletadas",
            })}
          </span>
        </div>
      </div>
      <div className="card-body p-9">
        <div
          ref={chartRef}
          id="kt_charts_widget_1_chart"
          style={{ height: "350px" }}
        />
      </div>
    </div>
  );
};

function getChartOptions(
  height: number,
  data: any,
  intl: IntlShape
): ApexOptions {
  return {
    series: data.map((item: any) => item.total),
    labels: data.map((item: any) =>
      getReviewStatus(item._id, intl).text.toLowerCase()
    ),
    chart: {
      fontFamily: "inherit",
      type: "pie",
      height: height,
      toolbar: {
        show: false,
      },
    },
    colors: data.map((item: any) => getReviewStatus(item._id, intl).color),
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return val + " avaliações";
        },
      },
    },
    //   colors: [baseColor, secondaryColor],
  };
}

export default ReviewsByChannel;
