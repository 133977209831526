/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from "react-intl";
import { AsideMenuItemWithSub } from "./AsideMenuItemWithSub";
import { AsideMenuItem } from "./AsideMenuItem";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

export function AsideMenuMain() {
  const intl = useIntl();
  const [isAdmin, setIsAdmin] = useState(false);

  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      const metadata =
        user["https://reviews-api.konfidency.com.br/app_metadata"];
      if (metadata.customer === "konfidency") {
        setIsAdmin(true);
      }
    }
  }, []);

  return (
    <>
      <AsideMenuItem
        to="/dashboard"
        icon="/media/icons/duotone/Design/PenAndRuller.svg"
        title={intl.formatMessage({
          id: "menu.dashboard",
          defaultMessage: "Dashboard",
        })}
        fontIcon="bi-app-indicator"
      />
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({
              id: "aside.menu.reviews.section.title",
              defaultMessage: "Konfidency Reviews",
            })}
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/reviews"
        title={intl.formatMessage({
          id: "aside.submenu.product-reviews",
          defaultMessage: "Avaliações de produtos",
        })}
        fontIcon="bi-archive"
        icon="/media/icons/duotone/General/Star.svg"
      >
        <AsideMenuItem
          to="/reviews/moderate"
          title={intl.formatMessage({
            id: "aside.submenu.item.moderation",
            defaultMessage: "Moderação",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/reviews/all"
          title={intl.formatMessage({
            id: "aside.submenu.item.all-reviews",
            defaultMessage: "Todas avaliações",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/reviews/removed"
          title={intl.formatMessage({
            id: "aside.submenu.item.rejected-reviews",
            defaultMessage: "Avaliações reprovadas",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/reviews/merchant-center"
          title={intl.formatMessage({
            id: "aside.submenu.item.google-merchant-center",
            defaultMessage: "Google Merchant Center",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/reviews/exports"
          title={intl.formatMessage({
            id: "aside.submenu.item.exported-reports",
            defaultMessage: "Relatórios exportados",
          })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/store-reviews"
        title={intl.formatMessage({
          id: "aside.submenu.store-reviews",
          defaultMessage: "Avaliações de loja",
        })}
        fontIcon="bi-archive"
        icon="/media/icons/duotone/Home/Building.svg"
      >
        <AsideMenuItem
          to="/store-reviews/moderate"
          title={intl.formatMessage({
            id: "aside.submenu.item.moderation",
            defaultMessage: "Moderação",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/store-reviews/all"
          title={intl.formatMessage({
            id: "aside.submenu.item.all-reviews",
            defaultMessage: "Todas avaliações",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/store-reviews/removed"
          title={intl.formatMessage({
            id: "aside.submenu.item.rejected-reviews",
            defaultMessage: "Avaliações reprovadas",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/store-reviews/exports"
          title={intl.formatMessage({
            id: "aside.submenu.item.exported-reports",
            defaultMessage: "Relatórios exportados",
          })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/questions"
        title={intl.formatMessage({
          id: "aside.submenu.faq",
          defaultMessage: "Perguntas & Respostas",
        })}
        fontIcon="bi-archive"
        icon="/media/icons/duotone/Code/Question-circle.svg"
      >
        <AsideMenuItem
          to="/questions/unanswered"
          title={intl.formatMessage({
            id: "aside.submenu.item.unanswered",
            defaultMessage: "Não respondidas",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/questions/all"
          title={intl.formatMessage({
            id: "aside.submenu.item.all-questions",
            defaultMessage: "Todas as perguntas",
          })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/products"
        title={intl.formatMessage({
          id: "aside.submenu.products",
          defaultMessage: "Produtos",
        })}
        fontIcon="bi-archive"
        icon="/media/icons/duotone/Shopping/Barcode.svg"
      >
        <AsideMenuItem
          to="/products/all"
          title={intl.formatMessage({
            id: "aside.submenu.item.all-products",
            defaultMessage: "Todas os produtos",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/products/report"
          title={intl.formatMessage({
            id: "aside.submenu.item.report",
            defaultMessage: "Relatório",
          })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/notifications"
        title={intl.formatMessage({
          id: "aside.submenu.notifications",
          defaultMessage: "Clientes",
        })}
        icon="/media/icons/duotone/Communication/Chat5.svg"
        fontIcon="bi-bell-fill"
      >
        <AsideMenuItem
          to="/notifications/sequences"
          title={intl.formatMessage({
            id: "aside.submenu.item.interval-management",
            defaultMessage: "Gerenciar cadência",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/trigger"
          title={intl.formatMessage({
            id: "aside.submenu.item.send-manually",
            defaultMessage: "Enviar manualmente",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/templates"
          title={intl.formatMessage({
            id: "aside.submenu.item.email-templates",
            defaultMessage: "Templates de e-mail",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/test"
          title={intl.formatMessage({
            id: "aside.submenu.item.send-test",
            defaultMessage: "Enviar teste",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/bulk-send"
          title={intl.formatMessage({
            id: "aside.submenu.item.send-bulk",
            defaultMessage: "Enviar base histórica",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/notifications/whatsapp-settings"
          title={intl.formatMessage({
            id: "aside.submenu.item.whatsapp-settings",
            defaultMessage: "Definições WhatsAp",
          })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      <AsideMenuItemWithSub
        to="/crafted/accounts"
        title={intl.formatMessage({
          id: "aside.submenu.settings",
          defaultMessage: "Clientes",
        })}
        icon="/media/icons/duotone/Shopping/Settings.svg"
        fontIcon="bi-person"
      >
        <AsideMenuItem
          to="/review-settings/store"
          title={intl.formatMessage({
            id: "aside.submenu.item.store-settings",
            defaultMessage: "Configurações da loja",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/review-settings/attributes"
          title={intl.formatMessage({
            id: "aside.submenu.item.review-attributes",
            defaultMessage: "Atributos de avaliações",
          })}
          hasBullet={true}
        />
        <AsideMenuItemWithSub
          to="/crafted/accounts"
          title={intl.formatMessage({
            id: "aside.submenu.item.review-topics",
            defaultMessage: "Tópicos de avaliações",
          })}
          hasBullet={true}
        >
          <AsideMenuItem
            to="/review-settings/topics"
            title={intl.formatMessage({
              id: "aside.submenu.item.submenu.products",
              defaultMessage: "Produtos",
            })}
            hasBullet={true}
          />
          <AsideMenuItem
            to="/store-reviews/settings/topics"
            title={intl.formatMessage({
              id: "aside.submenu.item.submenu.store",
              defaultMessage: "Loja",
            })}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
        <AsideMenuItem
          to="/review-settings/coupons"
          title={intl.formatMessage({
            id: "aside.submenu.item.discount-voucher",
            defaultMessage: "Cupons de desconto",
          })}
          hasBullet={true}
        />{" "}
        <AsideMenuItem
          to="/notifications/store-cadence"
          title={intl.formatMessage({
            id: "aside.submenu.item.notifications",
            defaultMessage: "Notificações",
          })}
          hasBullet={true}
        />
        <AsideMenuItemWithSub
          to="/review-settings/integrations/webhooks"
          title={intl.formatMessage({
            id: "aside.submenu.item.integrations",
            defaultMessage: "Integrações",
          })}
          hasBullet={true}
        >
          <AsideMenuItem
            to="/review-settings/integrations/webhooks"
            title={intl.formatMessage({
              id: "aside.submenu.item.submenu.webhooks",
              defaultMessage: "Webhooks",
            })}
            hasBullet={true}
          />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub>
      <div className="menu-item">
        <div className="menu-content pt-8 pb-2">
          <span className="menu-section text-muted text-uppercase fs-8 ls-1">
            {intl.formatMessage({
              id: "aside.menu.quest.section.title",
              defaultMessage: "Konfidency Quest",
            })}
          </span>
        </div>
      </div>
      <AsideMenuItemWithSub
        to="/nps"
        title={intl.formatMessage({
          id: "aside.submenu.nps",
          defaultMessage: "NPS",
        })}
        fontIcon="bi-archive"
        icon="/media/icons/duotone/General/Smile.svg"
      >
        <AsideMenuItem
          to="/nps/overview"
          title={intl.formatMessage({
            id: "aside.submenu.item.overview",
            defaultMessage: "Visão geral",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/nps/responses"
          title={intl.formatMessage({
            id: "aside.submenu.item.responses",
            defaultMessage: "Respostas",
          })}
          hasBullet={true}
        />
        <AsideMenuItem
          to="/nps/settings"
          title={intl.formatMessage({
            id: "aside.submenu.item.survey-configuration",
            defaultMessage: "Configurar pesquisa",
          })}
          hasBullet={true}
        />
      </AsideMenuItemWithSub>
      {isAdmin && (
        <>
          <div className="menu-item">
            <div className="menu-content pt-8 pb-2">
              <span className="menu-section text-muted text-uppercase fs-8 ls-1">
                {intl.formatMessage({
                  id: "aside.menu.admin.section.title",
                  defaultMessage: "Asgard",
                })}
              </span>
            </div>
          </div>
          <AsideMenuItemWithSub
            to="/customers"
            title={intl.formatMessage({
              id: "aside.submenu.customer",
              defaultMessage: "Clientes",
            })}
            fontIcon="bi-archive"
            icon="/media/icons/duotone/General/Smile.svg"
          >
            <AsideMenuItem
              to="/customers/list"
              title={intl.formatMessage({
                id: "aside.submenu.item.customer-management",
                defaultMessage: "Gerenciar clientes",
              })}
              hasBullet={true}
            />
            <AsideMenuItem
              to="/customers/new"
              title={intl.formatMessage({
                id: "aside.submenu.item.new-customer",
                defaultMessage: "Novo cliente",
              })}
              hasBullet={true}
            />
            <AsideMenuItem
              to="/customers/import-review"
              title={intl.formatMessage({
                id: "aside.submenu.item.import-review",
                defaultMessage: "Importar avaliações",
              })}
              hasBullet={true}
            />
          </AsideMenuItemWithSub>
          <AsideMenuItem
            to="/cache-manager/clear-cache"
            title={intl.formatMessage({
              id: "aside.submenu.clean-cache",
              defaultMessage: "Limpar cache",
            })}
            hasBullet={false}
            fontIcon="bi-memory"
            icon="/media/icons/duotone/General/Thunder.svg"
          />
        </>
      )}
    </>
  );
}
