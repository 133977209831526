import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../_metronic/assets/ts/_utils";
import { IntlShape, useIntl } from "react-intl";

type Props = {
  data: any;
};

const WeeklyReviewsDistribution: FC<Props> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data, intl)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div className="card h-500px">
      <div className="card-header border-0 pt-9">
        <div className="card-title m-0 flex-column align-items-start">
          <span className="card-label fw-bolder fs-3 mb-0">
            {intl.formatMessage({
              id: "page.dashboard.score-distribution-by-week",
              defaultMessage: "Distribuição das notas por semana",
            })}
          </span>
          <span className="text-gray-400 mb-2 fw-semibold fs-7">
            {intl.formatMessage({
              id: "page.dashboard.score-distribution-by-week-caption",
              defaultMessage: "Baseado em todas as avaliações coletadas",
            })}
          </span>
        </div>
      </div>

      <div className="card-body p-9">
        <div
          ref={chartRef}
          id="kt_charts_widget_1_chart"
          style={{ height: "350px" }}
        />
      </div>
    </div>
  );
};

export default WeeklyReviewsDistribution;

function getDateOfISOWeek(weekYear: string) {
  var w = parseInt(weekYear.substr(5, 2));
  var y = parseInt(weekYear.substr(0, 4));
  var simple = new Date(y, 0, 1 + (w - 1) * 7);
  var dow = simple.getDay();
  var ISOweekStart = simple;
  if (dow <= 4) ISOweekStart.setDate(simple.getDate() - simple.getDay());
  else ISOweekStart.setDate(simple.getDate() + 8 - simple.getDay() - 1);
  return ISOweekStart.toLocaleDateString();
}

function getChartOptions(
  height: number,
  data: any,
  intl: IntlShape
): ApexOptions {
  // const labelColor = getCSSVariableValue('--bs-gray-500')
  // const borderColor = getCSSVariableValue('--bs-gray-200')
  // const baseColor = getCSSVariableValue('--bs-primary')
  // const secondaryColor = getCSSVariableValue('--bs-gray-300')

  return {
    series: [
      {
        name: intl.formatMessage({
          id: "chart-1-star",
          defaultMessage: "1 estrela",
        }),
        data: data.map((item: any) => item.star1),
      },
      {
        name: intl.formatMessage({
          id: "chart-2-stars",
          defaultMessage: "2 estrelas",
        }),
        data: data.map((item: any) => item.star2),
      },
      {
        name: intl.formatMessage({
          id: "chart-3-stars",
          defaultMessage: "3 estrelas",
        }),
        data: data.map((item: any) => item.star3),
      },
      {
        name: intl.formatMessage({
          id: "chart-4-stars",
          defaultMessage: "4 estrelas",
        }),
        data: data.map((item: any) => item.star4),
      },
      {
        name: intl.formatMessage({
          id: "chart-5-stars",
          defaultMessage: "5 estrelas",
        }),
        data: data.map((item: any) => item.star5),
      },
    ],
    chart: {
      fontFamily: "inherit",
      type: "bar",
      stacked: true,
      stackType: "100%",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#ee4266", "#f77f00", "#ffd23f", "#3bceac", "#0ead69"],
    stroke: {
      show: true,
      width: 2,
    },
    xaxis: {
      categories: data.map((item: any) => {
        return item._id;
      }),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          // colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis: {
      labels: {
        style: {
          // colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return intl.formatMessage(
            { id: "chart-review-total", defaultMessage: `${val} avaliações` },
            { total: val }
          );
        },
      },
    },
    //   colors: [baseColor, secondaryColor],
    grid: {
      // borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
