interface CustomerSelectorProps {
  selectedCustomer: string;
  user: any;
  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
}

export default function CustomerSelector({
  selectedCustomer,
  user,
  onChange,
}: CustomerSelectorProps) {
  if (
    !user ||
    !user["https://reviews-api.konfidency.com.br/app_metadata"] ||
    !user["https://reviews-api.konfidency.com.br/app_metadata"].customers ||
    !Array.isArray(
      user["https://reviews-api.konfidency.com.br/app_metadata"].customers
    )
  ) {
    return null;
  }

  return (
    <div className="d-flex align-items-center">
      <select
        className="form-select form-select-white h-50px"
        value={selectedCustomer}
        onChange={onChange}
      >
        {user[
          "https://reviews-api.konfidency.com.br/app_metadata"
        ].customers.map((customer: string, index: number) => {
          return (
            <option key={index} value={customer}>
              {customer}
            </option>
          );
        })}
      </select>
    </div>
  );
}
