/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { FC } from "react";
import { useIntl } from "react-intl";
import { KTSVG, toAbsoluteUrl } from "../../../_metronic/helpers";
import { PageTitle } from "../../../_metronic/layout/core";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";
import axios from "axios";
import WeeklyReviews from "./WeeklyReviews";
import RatingDistribution from "./RatingDistribution";
import ReviewsByChannel from "./ReviewsByChannel";
import ReviewsByStatus from "./ReviewsByStatus";
import ReviewsWithText from "./ReviewsWithText";
import AverageRating from "./AverageRating";
import ProductsOverview from "./ProductsOverview";
import TotalReviews from "./TotalReviews";
import WeeklyReviewsDistribution from "./WeeklyReviewsDistribution";
import TopicsMap from "./TopicsMap";
import { Alert } from "react-bootstrap-v5";
import { Link } from "react-router-dom";
import NotificationStatsV2 from "./NotificationStatsV2";

interface CustomerModel {
  name: string;
  logo: string | undefined;
  storeUrl: string | undefined;
  settings: {
    enableStoreReview: boolean;
  };
}

interface StatsModel {
  avgRating: number;
  reviewCount: number;
  pendingReviews: number;
  reviewsLastWeek: number;
  productCount: number;
  totalProducts: number;
  totalReviews: number;
  reviewsByWeek: any;
  reviewsByWeekRating: any;
  reviewsByRating: any;
  reviewsByChannel: any;
  reviewsByStatus: any;
  reviewsWithText: any;
  notificationStats: any;
  positiveReviewsTopics: any;
  negativeReviewsTopics: any;
}

const DashboardPage: FC = () => {
  var [customer, setCustomer] = useState<CustomerModel | undefined>(undefined);
  var [stats, setStats] = useState<StatsModel | undefined>(undefined);
  var [isLoadingNotificationStats, setIsLoadingNotificationStats] =
    useState(false);
  var [notificationStats, setNotificationStats] = useState<any>(null);
  var [storeStats, setStoreStats] = useState<StatsModel | undefined>(undefined);
  var [showOldStats, setShowOldStats] = useState(false);
  const [currentTab, setCurrentTab] = useState(0);
  const intl = useIntl();

  async function getData() {
    const result = await axios.get(`info`);
    setCustomer(result.data);

    const statsData = await axios.get(`stats`);
    setStats(statsData.data);

    const storeStatsData = await axios.get(`store-stats`);
    setStoreStats(storeStatsData.data);

    setIsLoadingNotificationStats(true);
    const notificationStatsData = await axios.get(`notification-stats`);
    setNotificationStats(notificationStatsData.data);
    setIsLoadingNotificationStats(false);
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      {/* begin::Row */}
      {customer && (
        <div className="card mb-5 mb-xl-10">
          <div className="card-body pt-9 pb-0">
            <div className="d-flex flex-wrap flex-sm-nowrap mb-3">
              {customer.logo && (
                <div className="me-7 mb-4">
                  <div className="symbol symbol-100px symbol-lg-160px symbol-fixed position-relative ">
                    <img
                      src={customer.logo}
                      alt={customer.name}
                      style={{ objectFit: "contain" }}
                    />
                  </div>
                </div>
              )}

              <div className="flex-grow-1">
                <div className="d-flex justify-content-between align-items-start flex-wrap mb-2">
                  <div className="d-flex flex-column">
                    <div className="d-flex align-items-center mb-4">
                      <a
                        href="#"
                        className="text-gray-800 text-hover-primary fs-2 fw-bolder me-1"
                      >
                        {customer.name}
                      </a>
                    </div>
                    <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                      <KTSVG
                        path="/media/icons/duotone/Map/Marker1.svg"
                        className="svg-icon-4 me-1"
                      />
                      Brasil
                    </div>
                    {customer.storeUrl && (
                      <div className="d-flex flex-wrap fw-bold fs-6 mb-4 pe-2">
                        <a
                          href={customer.storeUrl}
                          className="d-flex align-items-center text-hover-primary me-5 mb-2"
                        >
                          <KTSVG
                            path="/media/icons/duotone/Home/Earth.svg"
                            className="svg-icon-4 me-1"
                          />
                          {customer.storeUrl}
                        </a>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {stats && (
        <>
          {customer?.settings?.enableStoreReview && (
            <div className="row g-6 g-xl-9 mb-10">
              <div className="text-end">
                <button
                  className={`btn btn-sm ${
                    currentTab == 0 ? "btn-secondary" : "btn-light-secondary"
                  } me-2`}
                  onClick={() => setCurrentTab(0)}
                >
                  {intl.formatMessage({
                    id: "page.dashboard.product-reviews",
                    defaultMessage: "Avaliações de produtos",
                  })}
                </button>
                <button
                  className={`btn btn-sm ${
                    currentTab == 1 ? "btn-secondary" : "btn-light-secondary"
                  } me-2`}
                  onClick={() => setCurrentTab(1)}
                >
                  {intl.formatMessage({
                    id: "page.dashboard.store-reviews",
                    defaultMessage: "Avaliações da loja",
                  })}
                </button>
              </div>
            </div>
          )}

          {currentTab == 0 && stats.reviewCount > 0 && (
            <>
              <div className="row g-6 g-xl-9 mb-10">
                <div className="col-md-4 col-sm-4 col-xl-4">
                  <TotalReviews
                    published={stats.reviewCount}
                    total={stats.totalReviews}
                  />
                </div>
                <div className="col-md-4 col-sm-4 col-xl-4">
                  <AverageRating rating={stats?.avgRating} />
                </div>
                <div className="col-md-4 col-sm-4 col-xl-4">
                  <ProductsOverview
                    count={stats.productCount}
                    total={stats.totalProducts}
                  />
                </div>
              </div>

              <div className="row g-6 g-xl-9 mb-10">
                <div className="col-md-12 col-xl-8">
                  <WeeklyReviews data={stats?.reviewsByWeek} />
                </div>
                <div className="col-md-12 col-xl-4">
                  <RatingDistribution
                    data={stats?.reviewsByRating}
                    totalReviews={stats.reviewCount}
                  />
                </div>
              </div>

              <div className="row g-6 g-xl-9 mb-10">
                <div className="col-md-12 col-xl-12">
                  <WeeklyReviewsDistribution
                    data={stats?.reviewsByWeekRating}
                  />
                </div>
              </div>

              <div className="row g-6 g-xl-9 mb-10">
                {stats?.positiveReviewsTopics[0].facet?.topicsFacet?.buckets
                  ?.length > 0 && (
                  <div className="col-md-12 col-xl-6">
                    <TopicsMap
                      data={stats?.positiveReviewsTopics}
                      title="Avaliações positivas - últimos 30 dias"
                    />
                  </div>
                )}
                {stats?.negativeReviewsTopics[0].facet?.topicsFacet?.buckets
                  ?.length > 0 && (
                  <div className="col-md-12 col-xl-6">
                    <TopicsMap
                      data={stats?.negativeReviewsTopics}
                      title="Avaliações negativas - últimos 30 dias"
                    />
                  </div>
                )}
              </div>

              <div className="row g-6 g-xl-9 mb-10">
                <div className="col-md-4 col-xl-4">
                  <ReviewsByChannel data={stats?.reviewsByChannel} />
                </div>
                <div className="col-md-4 col-xl-4">
                  <ReviewsByStatus data={stats?.reviewsByStatus} />
                </div>
                <div className="col-md-4 col-xl-4">
                  <ReviewsWithText data={stats?.reviewsWithText} />
                </div>
              </div>

              {showOldStats === false && (
                <>
                  <div className="row g-6 g-xl-9 mb-10">
                    <div className="col-md-12">
                      <NotificationStatsV2
                        source="email"
                        title="E-mail"
                        data={notificationStats}
                        loading={isLoadingNotificationStats}
                      />
                    </div>
                  </div>
                  <div className="row g-6 g-xl-9 mb-10">
                    <div className="col-md-12">
                      <NotificationStatsV2
                        source="whatsapp"
                        title="WhatsApp"
                        data={notificationStats}
                        loading={isLoadingNotificationStats}
                      />
                    </div>
                  </div>
                </>
              )}
            </>
          )}

          {currentTab == 0 && stats.reviewCount == 0 && (
            <>
              <Alert className="bg-light-primary border border-primary border-dashed d-flex flex-row">
                <div className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                  <KTSVG path="/media/icons/duotone/Home/Bulb1.svg" />
                </div>

                <div className="d-flex flex-column pe-0 pe-sm-10 flex-grow-1">
                  <h5>Sua loja ainda não tem avaliações publicadas</h5>
                  {stats.pendingReviews == 0 ? (
                    <div>
                      Mas fique tranquilo, estamos trabalhando para coletar
                      avaliações de seus consumidores.
                    </div>
                  ) : (
                    <div>
                      Você tem{" "}
                      <strong>{stats.pendingReviews} avaliações</strong> para
                      moderar
                    </div>
                  )}
                </div>
                {stats.pendingReviews > 0 && (
                  <Link to="/reviews/moderate" className="btn btn-primary">
                    Moderar avaliações
                  </Link>
                )}
              </Alert>
            </>
          )}

          {currentTab == 1 && storeStats && storeStats.reviewCount > 0 && (
            <>
              <div className="row g-6 g-xl-9 mb-10">
                <div className="col-md-6 col-sm-6 col-xl-6">
                  <TotalReviews
                    published={storeStats.reviewCount}
                    total={storeStats.totalReviews}
                  />
                </div>
                <div className="col-md-6 col-sm-6 col-xl-6">
                  <AverageRating rating={storeStats?.avgRating} />
                </div>
              </div>
              <div className="row g-6 g-xl-9 mb-10">
                <div className="col-md-12 col-xl-8">
                  <WeeklyReviews data={storeStats?.reviewsByWeek} />
                </div>
                <div className="col-md-12 col-xl-4">
                  <RatingDistribution
                    data={storeStats?.reviewsByRating}
                    totalReviews={storeStats.reviewCount}
                  />
                </div>
              </div>
              <div className="row g-6 g-xl-9 mb-10">
                <div className="col-md-12 col-xl-12">
                  <WeeklyReviewsDistribution
                    data={storeStats?.reviewsByWeekRating}
                  />
                </div>
              </div>
              <div className="row g-6 g-xl-9 mb-10">
                {storeStats?.positiveReviewsTopics[0].facet?.topicsFacet
                  ?.buckets?.length > 0 && (
                  <div className="col-md-12 col-xl-6">
                    <TopicsMap
                      data={storeStats?.positiveReviewsTopics}
                      title="Avaliações positivas - últimos 30 dias"
                    />
                  </div>
                )}
                {storeStats?.negativeReviewsTopics[0].facet?.topicsFacet
                  ?.buckets?.length > 0 && (
                  <div className="col-md-12 col-xl-6">
                    <TopicsMap
                      data={storeStats?.negativeReviewsTopics}
                      title="Avaliações negativas - últimos 30 dias"
                    />
                  </div>
                )}
              </div>
            </>
          )}

          {currentTab == 1 &&
            (storeStats?.reviewCount == null ||
              storeStats?.reviewCount == 0) && (
              <>
                <Alert className="bg-light-primary border border-primary border-dashed d-flex flex-row">
                  <div className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                    <KTSVG path="/media/icons/duotone/Home/Bulb1.svg" />
                  </div>

                  <div className="d-flex flex-column pe-0 pe-sm-10 flex-grow-1">
                    <h5>Sua loja ainda não tem avaliações publicadas</h5>
                    {storeStats?.pendingReviews == 0 ? (
                      <div>
                        Mas fique tranquilo, estamos trabalhando para coletar
                        avaliações de seus consumidores.
                      </div>
                    ) : (
                      <div>
                        Você tem{" "}
                        <strong>{storeStats?.pendingReviews} avaliações</strong>{" "}
                        para moderar
                      </div>
                    )}
                  </div>
                  {storeStats != null &&
                    storeStats.pendingReviews != null &&
                    storeStats.pendingReviews > 0 && (
                      <Link
                        to="/store-reviews/moderate"
                        className="btn btn-primary"
                      >
                        Moderar avaliações
                      </Link>
                    )}
                </Alert>
              </>
            )}
        </>
      )}
    </>
  );
};

const DashboardWrapper: FC = () => {
  const intl = useIntl();
  return (
    <>
      <PageTitle breadcrumbs={[]}>
        {intl.formatMessage({
          id: "menu.dashboard",
          defaultMessage: "Dashboard",
        })}
      </PageTitle>
      <DashboardPage />
    </>
  );
};

export { DashboardWrapper };
