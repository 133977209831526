import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef, useState } from "react";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../_metronic/assets/ts/_utils";
import { Alert, Spinner } from "react-bootstrap-v5";
import axios from "axios";
import { KTSVG } from "../../../_metronic/helpers";
import { IntlShape, useIntl } from "react-intl";

type Props = {
  source: string;
  title: string;
  loading: boolean;
  data: any;
};

const NotificationStatsV2: FC<Props> = ({ source, title, loading, data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();
  const [hasData, setHasData] = useState(false);

  useEffect(() => {
    if (!data || data.length === 0) return;
    if (!chartRef.current) {
      return;
    }

    const totalNotifications = data
      ?.map((x: any) => x[source].notifications)
      .reduce((a: number, b: number) => a + b);
    setHasData(totalNotifications && totalNotifications > 0);

    if (!totalNotifications || totalNotifications === 0) return;

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data, source, intl)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef, loading, data, hasData]);

  return (
    <div className="card h-500px">
      <div className="card-header border-0 pt-9">
        <div className="card-title m-0">
          <span className="card-label fw-bolder fs-3 mb-1">
            {intl.formatMessage(
              {
                id: "page.dashboard.notification-by-week.title",
                defaultMessage: `Notificações por semana - ${title}`,
              },
              { title }
            )}
          </span>
        </div>
      </div>

      <div className="card-body p-9 text-center">
        {loading ? (
          <div className="text-center">
            <span className="spinner-border spinner-border-lg spinner-border-primary align-middle"></span>
          </div>
        ) : (
          <>
            {(!data || !hasData) && !loading && (
              <Alert className="bg-light-primary border border-primary border-dashed d-flex flex-row">
                <div className="svg-icon svg-icon-2hx svg-icon-primary me-4 mb-5 mb-sm-0">
                  <KTSVG path="/media/icons/duotone/Home/Bulb1.svg" />
                </div>

                <div className="d-flex flex-column pe-0 pe-sm-10 flex-grow-1">
                  <h5>
                    {intl.formatMessage(
                      {
                        id: "page.dashboard.notification-by-week.no-data",
                        defaultMessage: `Sem notificações por ${title} nas últimas 12 semanas`,
                      },
                      { title }
                    )}
                  </h5>
                  <div>
                    {intl.formatMessage({
                      id: "page.dashboard.notification-by-week.help-text-1",
                      defaultMessage: "Se precisar de ajuda",
                    })}
                    ,{" "}
                    <a
                      href="javascript:;"
                      onClick={() => {
                        (window as any).Intercom("show");
                      }}
                    >
                      {intl.formatMessage({
                        id: "page.dashboard.notification-by-week.help-text-2",
                        defaultMessage:
                          "fale com nosso time de customer success",
                      })}
                    </a>
                  </div>
                </div>
              </Alert>
            )}
            <div
              ref={chartRef}
              id="kt_charts_widget_1_chart"
              style={{ height: "350px" }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default NotificationStatsV2;

function getChartOptions(
  height: number,
  data: any,
  source: string,
  intl: IntlShape
): ApexOptions {
  // const labelColor = getCSSVariableValue('--bs-gray-500')
  // const borderColor = getCSSVariableValue('--bs-gray-200')
  // const baseColor = getCSSVariableValue('--bs-primary')
  // const secondaryColor = getCSSVariableValue('--bs-gray-300')
  const maxBarSize = data.reduce(function (a: any, b: any) {
    var maxA =
      typeof a == "object"
        ? Math.max(
            a[source].notifications ?? 0,
            a[source].opens ?? 0,
            a[source].reviews ?? 0
          )
        : a;
    var maxB =
      typeof b == "object"
        ? Math.max(
            b[source].notifications ?? 0,
            b[source].opens ?? 0,
            b[source].reviews ?? 0
          )
        : b;
    return Math.max(maxA, maxB);
  });

  const maxLineSize = data.reduce(function (a: any, b: any) {
    var maxA =
      typeof a == "object"
        ? Math.max(
            a[source].conversionRate * 100 ?? 0,
            a[source].openRate * 100 ?? 0,
            100
          )
        : a;
    var maxB =
      typeof b == "object"
        ? Math.max(
            b[source].conversionRate * 100 ?? 0,
            b[source].openRate * 100 ?? 0,
            100
          )
        : b;
    return Math.max(maxA, maxB);
  });

  const series =
    source === "email"
      ? [
          {
            name: intl.formatMessage({
              id: "chart-sent",
              defaultMessage: "Enviadas",
            }),
            data: data.map((item: any) => item.email.notifications),
            type: "bar",
          },
          {
            name: intl.formatMessage({
              id: "chart-open",
              defaultMessage: "Abertas",
            }),
            data: data.map((item: any) => item.email.opens),
            type: "bar",
          },
          {
            name: intl.formatMessage({
              id: "chart-review-collected",
              defaultMessage: "Avaliações coletadas",
            }),
            data: data.map((item: any) => item.email.reviews),
            type: "bar",
          },
          {
            name: intl.formatMessage({
              id: "chart-open-rate",
              defaultMessage: "Taxa de abertura",
            }),
            data: data.map((item: any) =>
              (item.email.openRate * 100).toFixed(2)
            ),
            type: "line",
          },
          {
            name: intl.formatMessage({
              id: "chart-conversion-rate",
              defaultMessage: "Taxa de conversão",
            }),
            data: data.map((item: any) =>
              (item.email.conversionRate * 100).toFixed(2)
            ),
            type: "line",
          },
        ]
      : [
          {
            name: intl.formatMessage({
              id: "chart-sent",
              defaultMessage: "Enviadas",
            }),
            data: data.map((item: any) => item.whatsapp.notifications),
            type: "bar",
          },
          {
            name: intl.formatMessage({
              id: "chart-collected",
              defaultMessage: "Avaliações coletadas",
            }),
            data: data.map((item: any) => item.whatsapp.reviews),
            type: "bar",
          },
          {
            name: intl.formatMessage({
              id: "chart-conversion-rate",
              defaultMessage: "Taxa de conversão",
            }),
            data: data.map((item: any) =>
              (item.whatsapp.conversionRate * 100).toFixed(2)
            ),
            type: "line",
          },
        ];

  return {
    series: series,
    chart: {
      fontFamily: "inherit",
      type: "bar",
      height: height,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: "30%",
        borderRadius: 5,
      },
    },
    legend: {
      show: true,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
    },
    xaxis: {
      categories: data.map((item: any) => {
        return item.weekYear;
      }),
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          // colors: labelColor,
          fontSize: "12px",
        },
      },
    },
    yaxis:
      source === "email"
        ? [
            {
              min: 0,
              max: maxBarSize,
              labels: {
                show: true,
                style: {
                  fontSize: "12px",
                },
              },
            },
            {
              min: 0,
              max: maxBarSize,
              labels: {
                show: false,
              },
            },
            {
              min: 0,
              max: maxBarSize,
              labels: {
                show: false,
              },
            },
            {
              opposite: true,
              floating: true,
              min: 0,
              max: maxLineSize,
              labels: {
                show: true,
              },
            },
            {
              opposite: true,
              floating: true,
              min: 0,
              max: maxLineSize,
              labels: {
                show: false,
              },
            },
          ]
        : [
            {
              min: 0,
              max: maxBarSize,
              labels: {
                show: true,
                style: {
                  fontSize: "12px",
                },
              },
            },
            {
              min: 0,
              max: maxBarSize,
              labels: {
                show: false,
                style: {
                  fontSize: "12px",
                },
              },
            },
            {
              opposite: true,
              floating: true,
              min: 0,
              max: 100,
              labels: {
                show: false,
              },
            },
          ],
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      shared: true,
      intersect: false,
      y:
        source === "email"
          ? [
              {
                formatter: function (val) {
                  return intl.formatMessage(
                    {
                      id: "chart-notification-total",
                      defaultMessage: `${val} notificações`,
                    },
                    { total: val }
                  );
                },
              },
              {
                formatter: function (val) {
                  return intl.formatMessage(
                    {
                      id: "chart-notification-total",
                      defaultMessage: `${val} notificações`,
                    },
                    { total: val }
                  );
                },
              },
              {
                formatter: function (val) {
                  return intl.formatMessage(
                    {
                      id: "chart-review-total",
                      defaultMessage: `${val} avaliações`,
                    },
                    { total: val }
                  );
                },
              },
              {
                formatter: function (val) {
                  return val + "%";
                },
              },
              {
                formatter: function (val) {
                  return val + "%";
                },
              },
            ]
          : [
              {
                formatter: function (val) {
                  return intl.formatMessage(
                    {
                      id: "chart-notification-total",
                      defaultMessage: `${val} notificações`,
                    },
                    { total: val }
                  );
                },
              },
              {
                formatter: function (val) {
                  return intl.formatMessage(
                    {
                      id: "chart-review-total",
                      defaultMessage: `${val} avaliações`,
                    },
                    { total: val }
                  );
                },
              },
              {
                formatter: function (val) {
                  return val + "%";
                },
              },
            ],
    },
    //   colors: [baseColor, secondaryColor],
    grid: {
      // borderColor: borderColor,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
    },
  };
}
