import { FC } from "react";
import { useIntl } from "react-intl";

type Props = {
  rating: number;
};

const AverageRating: FC<Props> = ({ rating }) => {
  const intl = useIntl();
  return (
    <div className="card h-200px">
      <div className="card-header border-0 pt-9">
        <div className="card-title m-0 flex-column">
          <span className="card-label fw-bolder fs-3 mb-0">
            {intl.formatMessage({
              id: "page.dashboard.average-score",
              defaultMessage: "Avaliação média",
            })}
          </span>
          <span className="text-gray-400 mb-2 fw-semibold fs-7">
            {intl.formatMessage({
              id: "page.dashboard.published-reviews",
              defaultMessage: "Avaliações publicadas",
            })}
          </span>
        </div>
      </div>
      <div className="card-body p-9 pt-0">
        <div className="fs-3hx fw-bolder text-center">{rating.toFixed(2)}</div>
        <div className="rating col-md-8 justify-content-center w-100">
          <div className={`rating-label me-2 ${rating > 0 ? "checked" : ""}`}>
            <i className="bi bi-star-fill fs-2hx"></i>
          </div>
          <div className={`rating-label me-2 ${rating > 1 ? "checked" : ""}`}>
            <i className="bi bi-star-fill fs-2hx"></i>
          </div>
          <div className={`rating-label me-2  ${rating > 2 ? "checked" : ""}`}>
            <i className="bi bi-star-fill fs-2hx"></i>
          </div>
          <div className={`rating-label me-2  ${rating > 3 ? "checked" : ""}`}>
            <i className="bi bi-star-fill fs-2hx"></i>
          </div>
          <div className={`rating-label me-2  ${rating > 4 ? "checked" : ""}`}>
            <i className="bi bi-star-fill fs-2hx"></i>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AverageRating;
