import clsx from "clsx";
import { HeaderUserMenu } from "../../../../partials";

export default function UserMenuTopBar({ user }: { user: any }) {
  return (
    <div
      className={clsx("d-flex align-items-center", "ms-1 ms-lg-3")}
      id="kt_header_user_menu_toggle"
    >
      <div
        className={clsx("cursor-pointer symbol", "symbol-30px symbol-md-40px")}
        data-kt-menu-trigger="click"
        data-kt-menu-attach="parent"
        data-kt-menu-placement="bottom-end"
        data-kt-menu-flip="bottom"
      >
        {user && <img src={user.picture} alt=" " />}
      </div>
      <HeaderUserMenu />
    </div>
  );
}
