import ApexCharts, { ApexOptions } from "apexcharts";
import { FC, useEffect, useRef } from "react";
import {
  getCSS,
  getCSSVariableValue,
} from "../../../_metronic/assets/ts/_utils";
import { IntlShape, useIntl } from "react-intl";

type Props = {
  data: any;
};

const ReviewsWithText: FC<Props> = ({ data }) => {
  const chartRef = useRef<HTMLDivElement | null>(null);
  const intl = useIntl();

  useEffect(() => {
    if (!chartRef.current) {
      return;
    }

    const height = parseInt(getCSS(chartRef.current, "height"));

    const chart = new ApexCharts(
      chartRef.current,
      getChartOptions(height, data, intl)
    );
    if (chart) {
      chart.render();
    }

    return () => {
      if (chart) {
        chart.destroy();
      }
    };
  }, [chartRef]);

  return (
    <div className="card">
      <div className="card-header border-0 pt-9">
        <div className="card-title m-0 flex-column">
          <span className="card-label fw-bolder fs-3 mb-0">
            {intl.formatMessage({
              id: "page.dashboard.text-reviews",
              defaultMessage: "Avaliações com texto",
            })}
          </span>
          <span className="text-gray-400 mb-2 fw-semibold fs-7">
            {intl.formatMessage({
              id: "page.dashboard.published-reviews",
              defaultMessage: "Avaliações publicadas",
            })}
          </span>
        </div>
      </div>
      <div className="card-body p-9">
        <div
          ref={chartRef}
          id="kt_charts_widget_1_chart"
          style={{ height: "350px" }}
        />
      </div>
    </div>
  );
};

function getChartOptions(
  height: number,
  data: any,
  intl: IntlShape
): ApexOptions {
  return {
    series: data.map((item: any) => item.total),
    labels: data.map((item: any) =>
      item._id
        ? intl.formatMessage({
            id: "chart-with-text",
            defaultMessage: "Com texto",
          })
        : intl.formatMessage({
            id: "chart-without-text",
            defaultMessage: "Sem texto",
          })
    ),
    chart: {
      fontFamily: "inherit",
      type: "pie",
      height: height,
      toolbar: {
        show: false,
      },
    },
    colors: data.map((item: any) => (item._id ? "#63DE9C" : "#ED5564")),
    legend: {
      show: true,
      position: "bottom",
    },
    dataLabels: {
      enabled: true,
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      y: {
        formatter: function (val) {
          return intl.formatMessage(
            {
              id: "chart-review-total",
              defaultMessage: `${val} avaliações`,
            },
            { total: val }
          );
        },
      },
    },
    //   colors: [baseColor, secondaryColor],
  };
}

export default ReviewsWithText;
