import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useHistory } from "react-router-dom";
import CustomerSelector from "./customer-selector";
import UserMenuTopBar from "./user-menu";
import LanguageSelector from "./language-selector";

export default function TopBar() {
  const { user } = useAuth0();

  const [customer, setCustomer] = useState("");

  const history = useHistory();

  function onCustomerSelected(e: React.ChangeEvent<HTMLSelectElement>) {
    window.localStorage.setItem("activeCustomer", e.target.value);
    history.go(0);
  }

  useEffect(() => {
    if (user) {
      const metadata =
        user["https://reviews-api.konfidency.com.br/app_metadata"];

      const storageCustomer = window.localStorage.getItem("activeCustomer");
      const customer =
        storageCustomer != null ? storageCustomer! : metadata.customer;
      setCustomer(customer);
    }
  }, []);

  useEffect(() => {
    if (user && customer) {
      (window as any).intercomSettings = {
        app_id: "b5yhlscr",
        name: user?.name,
        email: user?.email,
        company: {
          company_id: customer,
          name: customer,
        },
      };
      try {
        (window as any).loadIntercom();
      } catch (err) {
        console.log("error setting customer on intercom chat");
      }
    }
  }, [user, customer]);

  return (
    <div className="d-flex align-items-stretch flex-shrink-0">
      <CustomerSelector
        selectedCustomer={customer}
        user={user}
        onChange={onCustomerSelected}
      />
      <LanguageSelector />
      <UserMenuTopBar user={user} />
    </div>
  );
}
