import { useIntl } from "react-intl";
import { useTranslation } from "../../../../../i18n/i18nProvider";
import flagBr from "./flags/br.svg";
import flagEs from "./flags/es.svg";
import clsx from "clsx";

const LANGUAGES = [
  {
    value: "pt-BR",
    label: "language.portuguese",
    fallbackLabel: "Português",
    flag: flagBr,
  },
  {
    value: "es",
    label: "language.spanish",
    fallbackLabel: "Espanhol",
    flag: flagEs,
  },
];

export default function LanguageSelector() {
  const intl = useIntl();
  const translation = useTranslation();
  function onLanguageChange(lang: string) {
    translation.setLanguage(lang);
  }

  const selectedLanguage = LANGUAGES.find(
    (lang) => lang.value === translation.language
  );
  return (
    <div className="menu menu-rounded" data-kt-menu="true">
      <div
        className="menu-item menu-dropdown pt-8"
        data-kt-menu-trigger="click"
        data-kt-menu-placement="bottom"
      >
        <a href="#" className="menu-link">
          <span className="menu-title position-relative">
            <span className="fs-12 rounded bg-light px-3 py-2 translate-middle-y top-50 end-0">
              {intl.formatMessage({
                id: selectedLanguage?.label,
                defaultMessage: selectedLanguage?.fallbackLabel,
              })}{" "}
              <img
                className="w-20px h-20px rounded-1 ms-2"
                src={selectedLanguage?.flag}
                alt={intl.formatMessage({
                  id: selectedLanguage?.label,
                  defaultMessage: selectedLanguage?.fallbackLabel,
                })}
              />
            </span>
          </span>
        </a>
        <div className="menu-sub menu-sub-dropdown">
          {LANGUAGES.map((lang) => (
            <div
              className="menu-item"
              key={lang.value}
              onClick={() => {
                onLanguageChange(lang.value);
              }}
            >
              <a
                href="#"
                className={clsx("menu-link d-flex justify-content-between", {
                  active: translation.language === lang.value,
                })}
              >
                {intl.formatMessage({
                  id: lang.label,
                  defaultMessage: lang.fallbackLabel,
                })}{" "}
                <img
                  className="w-20px h-20px rounded-1 ms-2"
                  src={lang.flag}
                  alt={`Bandeira ${intl.formatMessage({
                    id: lang.label,
                    defaultMessage: lang.fallbackLabel,
                  })}`}
                />
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
